/* quicksand-500 - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/quicksand-v21-latin-500.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/quicksand-v21-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/quicksand-v21-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/quicksand-v21-latin-500.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/quicksand-v21-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/quicksand-v21-latin-500.svg#Quicksand') format('svg');
  /* Legacy iOS */
}

/* quicksand-600 - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/quicksand-v21-latin-600.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/quicksand-v21-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/quicksand-v21-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/quicksand-v21-latin-600.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/quicksand-v21-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/quicksand-v21-latin-600.svg#Quicksand') format('svg');
  /* Legacy iOS */
}

/* quicksand-700 - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/quicksand-v21-latin-700.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/quicksand-v21-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/quicksand-v21-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/quicksand-v21-latin-700.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/quicksand-v21-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/quicksand-v21-latin-700.svg#Quicksand') format('svg');
  /* Legacy iOS */
}

html {
  max-height: 100vh;
  max-width: 100vw;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif !important;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  max-height: 100vh;
  max-width: 100vw;
  overflow: auto;
}

.react-infinite-scroll::-webkit-scrollbar-track {
  border-radius: 5px;
  margin: 20px;
}

.react-infinite-scroll::-webkit-scrollbar {
  height: 10px;
  width: 5px;
}

.react-infinite-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #9E9E9E;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

[role='tooltip'] {
  z-index: 2;
}

.slick-dots {
  bottom: -20px;
}

.slick-dots li.slick-active button:before {
  color: #3281e3;
}

.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
  color: #3281e3;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #3281e3;
  opacity: 0.35;
}

.slick-dots li {
  margin: 0;
}

.select .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-marginDense.MuiInputLabel-outlined {
  background-color: #fff;
  padding: 0 5px;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiPopover-root {
  z-index: 9999999999 !important;
}

.MuiAutocomplete-popper {
  z-index: 10000 !important;
}

/* ChatBox Modal */

.modalChatBoxInnerContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  color: #333;
  margin: 0 0 0 0;
  width: 80%;
  border-radius: 20px;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .modalChatBoxInnerContainer {
    width: 100%;
    height: 100%;
  }
}

.modalChatBoxInnerContainer:focus {
  outline: none;
}

.modalChatBoxInnerContainer .chatBoxRightSectionCardContent {
  height: calc(100vh - 76px) !important;
}

.modalChatBoxInnerContainer .chatBoxWrapper {
  height: calc(100vh - 60px - 15px);
}

@media only screen and (max-width: 600px) {
  .modalChatBoxInnerContainer .chatBoxWrapper {
    margin: 5px 2.5px;
    height: calc(100vh - 35px);
    overflow: hidden;
  }
}

[role='tooltip'] {
  z-index: 9999;
}

.templateListContainer {
  height: calc(100vh - 325px);
  overflow: auto;
  scrollbar-color: #9e9e9e;
  scrollbar-width: thin;
}

.templateListContainer::-webkit-scrollbar {
  width: 5px;
}

.templateListContainer::-webkit-scrollbar-track {
  border-radius: 10px;
}

.templateListContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #9e9e9e;
}

.radioButton .MuiSvgIcon-root {
  font-size: 1.25rem !important;
}

audio:focus {
  outline: none;
}

.recharts-default-tooltip {
  border-radius: 5px !important;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  cursor: pointer;
  padding-left: 0;
}

.pagination a {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 25px;
  min-width: 25px;
  border-radius: 50%;
  color: #727272;
}

.pagination a:focus {
  outline: none;
}

.pagination__link {
  font-weight: bold;
}

a.pagination__none {
  display: none !important;
}

.pagination__link--active a {
  color: #fff;
  background: #0382e2;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

:focus {
  outline: none !important;
}

#colorInputPicker {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  padding-right: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

#colorInputPicker::-webkit-color-swatch {
  border-radius: 15px;
  border: none;
}

#colorInputPicker::-moz-color-swatch {
  border-radius: 15px;
  border: none;
}

/*  ------------ Tools section ----------------------------- */
.boy-grey {
  background: url('../images/boy-grey.svg');
  display: block;
  width: 123px;
  height: 169px;
}

#colorInputPicker {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  padding-right: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

#colorInputPicker::-webkit-color-swatch {
  border-radius: 15px;
  border: none;
}

#colorInputPicker::-moz-color-swatch {
  border-radius: 15px;
  border: none;
}

.exit-white {
  background: url('../images/tools/exit-w.svg');
  display: block;
  width: 32px;
  height: 32px;
}

.exit-black {
  background: url('../images/tools/black/exit-b.svg');
  display: block;
  width: 32px;
  height: 32px;
}

.main-contact-black {
  background: url('../images/tools/black/main-img-b.svg');
  display: block;
  width: 64px;
  height: 64px;
}

.main-contact-white {
  background: url('../images/tools/main-img-w.svg');
  display: block;
  width: 64px;
  height: 64px;
}

.button-widget {
  width: 100%;
  height: 35px;
  background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
  border: 1px solid rgb(217, 217, 217);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;
  color: #727272;
  justify-content: space-between;
  font-size: 14px;
}

.button-single {
  width: 100%;
  margin: 0px 20px;
  height: 45px;
  background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
  border: 1px solid rgb(217, 217, 217);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;
  color: #727272;
  justify-content: flex-start;
}

.preview-widget-holder {
  background-image: url('../images/tools/background/widget-background-top.svg'),
    url('../images/tools/background/widget-background-middle.svg'),
    url('../images/tools/background/widget-background-bottom.svg');
  margin-top: 20px;
  width: 349px;
  margin-left: 25px;
  min-height: 492px;
  border-radius: 18px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15px;
  background-position: top, center 155px, bottom;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-origin: content-box;
  background-size: contain;
  background-color: #f8f8f8;
}

input.form-control:hover {
  border-color: #3281e3;
}

.whatsapp-button-widget:hover {
  border-color: #03e26b;
}

.whatsapp-button-widget:hover .whatsapp-button-widget-icon {
  color: #03e26b;
}

.multichannel-button-widget:hover {
  border-color: #0081E1;
}

.multichannel-button-widget:hover .multichannel-button-widget-icon {
  color: #0081E1;
}

.messenger-button-widget:hover {
  border-color: #00c6ff;
}

.messenger-button-widget:hover .messenger-button-widget-icon {
  color: #00c6ff;
}

.instagram-button-widget:hover {
  border-color: #d10868;
}

.instagram-button-widget:hover .instagram-button-widget-icon {
  color: #d10868;
}

.multiple-numbers-holder {
  display: flex;
  justify-content: center;
  padding: 20px 20px 0px 20px;
}

.multiple-numbers-container {
  border: 1px solid #d9d9d9;
  padding: 13px 20px 13px 20px;
  border-radius: 6px;
  width: 100%;
}

.whatsapp-arrow-icon {
  margin-right: 8px;
  height: 18px;
  color: #727272;
}

.messenger-arrow-icon {
  margin-right: 8px;
  height: 18px;
  color: #727272;
}

.instagram-arrow-icon {
  margin-right: 8px;
  height: 18px;
  color: #727272;
}

.whatsapp-numbers-container:hover {
  border-color: #03e26b;
}

.whatsapp-numbers-container:hover .whatsapp-button-widget-icon {
  color: #33b928;
}

.messenger-numbers-container:hover {
  border: 1px solid #00c6ff;
}

.messenger-numbers-container:hover .messenger-button-widget-icon {
  color: #00c6ff;
}

.instagram-numbers-container:hover {
  border: 1px solid #d10868;
}

.instagram-numbers-container:hover .instagram-button-widget-icon {
  color: #d10868;
}

.multichannel-button-widget:hover .multichannel-arrow-icon {
  color: #0081E1;
}

.multichannel-numbers-container:hover {
  border-color: #0081E1;
}

.multichannel-numbers-container:hover .multichannel-button-widget-icon {
  color: #0081E1;
}

.whatsapp-button-widget:hover .whatsapp-arrow-icon {
  color: #03e26b;
}

.messenger-button-widget:hover .messenger-arrow-icon {
  color: #00c6ff;
}

.instagram-button-widget:hover .instagram-arrow-icon {
  color: #d10868;
}

.multiple-numbers-title-container {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.multiple-numbers-title {
  display: flex;
  align-items: center;
}

.button-widget-container {
  display: flex;
  margin-top: 13px;
  justify-content: center;
}

.button-widget-icon {
  margin-right: 8px;
  font-size: initial;
  color: #727272;
}

.button-widget-title {
  color: #727272;
  font-weight: 500;
  font-size: 14px;
}

.button-widget-title-single {
  font-size: 14px;
}

.whatsapp-big-button-white {
  font-size: -webkit-xxx-large;
  color: white;
}

.whatsapp-big-button-black {
  font-size: -webkit-xxx-large;
  color: #3b3b3b;
}

.under-title-button-preview {
  display: flex;
  justify-content: center;
  font-weight: 500;
  color: #9e9e9e;
  font-size: 12px;
  margin-top: 20px;
}

.under-title-uniboxi-ref {
  margin-left: 5px;
  font-weight: bolder;
}

.under-title-uniboxi-ref:hover {
  cursor: pointer;
}

.export-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 51px;
  margin-left: 10px;
  width: 40%;
}

.export-widget-title {
  font-weight: bold;
  font-size: 18px;
  color: #3b3b3b;
  margin-bottom: 5px;
}

.export-widget-body {
  display: flex;
  justify-content: space-between;
}

.export-button-input {
  font-size: inherit;
  color: #727272;
  height: 51px;
}

.position-selector-tooltip {
  color: #9e9e9e;
  font-size: 12px;
  margin-bottom: 0;
  padding: 5px;
  width: 200px;
  line-height: initial;
}

.code-area {
  border-radius: 30px;
  margin: 20px !important;
  resize: none;
  width: 100%;
}

textarea#code {
  color: #9e9e9e;
}

textarea#code:focus {
  color: black;
}

.code-area::-webkit-scrollbar-track {
  margin: 20px;
}

.code-area::-webkit-scrollbar {
  width: 14px;
}

.multichannel-widget-big-button {
  height: 48px;
  width: 42px;
}

.code-area::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: #c8c8c8;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.container-son {
  width: 50%;
}

.container-father {
  display: flex;
  justify-content: space-between;
}

.whatsapp-tool {
  display: flex;
  justify-content: center;
}

.know-more-container {
  display: flex;
}

/* ---------------- RESPONSIVE ------------- */
@media only screen and (max-width: 992px) {
  .container-father {
    flex-direction: column;
  }

  .container-son {
    width: 100%;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .whatsapp-tool {
    display: none;
  }

  .know-more-container {
    flex-direction: column;
  }

  .testimonial-img-container {
    display: flex;
  }

  .testimonial-img {
    width: 50%;
  }
}